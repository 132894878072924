import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { globe } from 'ionicons/icons';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from 'ttag';

import { ReactComponent as EMLeadCaptureLogoDark } from '@assets/eventmobi-lead-capture-logo-dark.svg';
import { ReactComponent as EMLeadCaptureLogoLight } from '@assets/eventmobi-lead-capture-logo-light.svg';
import { ReactComponent as EMOnsiteLogoDark } from '@assets/eventmobi-logo-dark.svg';
import { ReactComponent as EMOnsiteLogoLight } from '@assets/eventmobi-logo-light.svg';
import GenericContent from '@components/Content';
import Product from '@custom-types/Product';
import RoutePath from '@custom-types/RoutePath';
import useDeviceTheme, { Theme } from '@hooks/useDeviceTheme';
import { Locale } from '@services/language-service';
import { generateRoute, getProduct } from '@services/navigation-service';
import { getLocale } from '@state/system';
import { Browser } from '~capacitor-plugins/browser';

export const PRIVACY_POLICY_URL_EN =
  'https://help.eventmobi.com/en/knowledge/onsite-app-data-privacy-statement';
export const PRIVACY_POLICY_URL_DE =
  'https://help.eventmobi.com/de/knowledge/datenschutzerkl%C3%A4rung';
export const TERMS_URL = 'https://www.eventmobi.com/terms-of-use';

const LogoContainer = styled.div`
  margin-top: 60px;
  margin-bottom: 20px;
`;

const Content = styled(GenericContent)`
  justify-content: center;
  height: auto;
  min-height: 100%;
`;

const Footer = styled(IonFooter)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const TextButton = styled(IonButton)`
  text-transform: capitalize;
`;

const LanguageIcon = styled(IonIcon)`
  margin-right: 5px;
`;

const Toolbar = styled(IonToolbar)`
  --background: transparent;
`;

const LOCALE_TO_SHORT_LABEL = {
  [Locale.De]: 'DE',
  [Locale.En]: 'EN',
  [Locale.Es]: 'ES',
  [Locale.Fr]: 'FR',
  [Locale.Pt]: 'PT',
};

type Props = {
  children: ReactElement;
  hideFooter?: boolean;
  hideLogo?: boolean;
  showLanguage?: boolean;
};

const Layout = ({ children, hideFooter = false, hideLogo = false, showLanguage = true }: Props) => {
  const locale = useSelector(getLocale);
  const companyName = 'EventMobi™';
  const deviceTheme = useDeviceTheme();
  const product = getProduct();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Toolbar>
          {showLanguage && (
            <IonButtons slot="primary">
              <IonButton
                fill="clear"
                color="primary"
                routerLink={generateRoute(RoutePath.Language)}
              >
                <LanguageIcon icon={globe} />
                {LOCALE_TO_SHORT_LABEL[locale]}
              </IonButton>
            </IonButtons>
          )}
        </Toolbar>
      </IonHeader>
      <IonContent>
        <Content>
          {!hideLogo && (
            <LogoContainer>
              {product !== Product.LeadCapture &&
                (deviceTheme === Theme.Dark ? <EMOnsiteLogoDark /> : <EMOnsiteLogoLight />)}

              {product === Product.LeadCapture &&
                (deviceTheme === Theme.Dark ? (
                  <EMLeadCaptureLogoDark />
                ) : (
                  <EMLeadCaptureLogoLight />
                ))}
            </LogoContainer>
          )}
          {children}
        </Content>
      </IonContent>
      {!hideFooter && (
        <Footer className="ion-no-border">
          <ButtonGroup>
            <TextButton
              role="button"
              fill="clear"
              size="small"
              // skipcq: JS-0417
              onClick={() => Browser.open({ url: TERMS_URL })}
            >
              {t`Terms of Use`}
            </TextButton>
            <TextButton
              role="button"
              fill="clear"
              size="small"
              // skipcq: JS-0417
              onClick={() => {
                const url = locale === Locale.De ? PRIVACY_POLICY_URL_DE : PRIVACY_POLICY_URL_EN;
                Browser.open({ url });
              }}
            >
              {t`Privacy Policy`}
            </TextButton>
          </ButtonGroup>
          {t`Powered by ${companyName}`}
        </Footer>
      )}
    </IonPage>
  );
};

export default Layout;
