/* eslint-disable import/no-extraneous-dependencies */
import { CapacitorBarcodeScannerPlugin } from '@capacitor/barcode-scanner/dist/esm/definitions';

export * from '@capacitor/barcode-scanner/dist/esm/definitions';
// We are intentionally using a mutable export so that we can mimic the original import
// syntax for Capacitor plugins, while still dynamically loading the correct capacitor version.
// Don't remove or try to fix the skipcq, as this syntax is mandatory for the compatibility bridge.
// skipcq: JS-E1009
export let CapacitorBarcodeScanner: CapacitorBarcodeScannerPlugin;

const load = ({
  CapacitorBarcodeScanner: capacitorBarcodeScanner,
}: {
  CapacitorBarcodeScanner: CapacitorBarcodeScannerPlugin;
}) => {
  CapacitorBarcodeScanner = capacitorBarcodeScanner;
};

export default load;
