import type {
  CapacitorGlobal,
  PluginListenerHandle as IPluginListenerHandle,
  RegisterPlugin,
} from '@capacitor/core/types/definitions';
import type { WebPlugin as IWebPlugin } from '@capacitor/core/types/web-plugin';

export type PluginListenerHandle = IPluginListenerHandle;
// We are intentionally using a mutable export so that we can mimic the original import
// syntax for Capacitor plugins, while still dynamically loading the correct capacitor version.
// Don't remove or try to fix the skipcq, as this syntax is mandatory for the compatibility bridge.
// skipcq: JS-E1009
export let Capacitor: CapacitorGlobal;
// skipcq: JS-E1009
export let registerPlugin: RegisterPlugin;
// skipcq: JS-E1009
export let WebPlugin: typeof IWebPlugin;

const load = (exported: {
  Capacitor: CapacitorGlobal;
  registerPlugin: RegisterPlugin;
  WebPlugin: typeof IWebPlugin;
}) => {
  Capacitor = exported.Capacitor;
  registerPlugin = exported.registerPlugin;
  WebPlugin = exported.WebPlugin;
};

export default load;
